import React from "react"
//import { Link } from "gatsby"

import LandingPage from "../components/landing-page"
import FixedImage from "../components/fixed-image"
import SEO from "../components/seo"


const IndexPage = () => (
  <LandingPage>
    <SEO title="Welcome" />
      <FixedImage alt="videophose - video encoding tool" filename="temporary-logo.png" />
  </LandingPage>
)

export default IndexPage
